import * as React from "react";
import useColor from "../../context/ColorContext";
import ContactForm from "./ContactForm";
import ContactPanel from "./ContactPanel";
import { footer } from "./style.css";

const Footer = () => {
    const { color } = useColor();

    return (
        <footer className={footer({ color })}>
            <ContactPanel />
            <ContactForm />
        </footer>
    );
};

export default Footer;
