import { motion } from "framer-motion";
import * as React from "react";
import Header from "../Header";
import { mainStyle } from "./style.css";
import "../../style/scrollbar.css";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <Helmet htmlAttributes={{ lang: "pl" }}>
                <title>Agencja reklamowa Goldfish</title>
                <meta
                    name="description"
                    content="
                    Profesjonalna produkcja elementów reklamy Out Of House.
                    Zajmujemy się drukiem, reklamą wizualną, dekoracją wnętrz
                    oraz montażem reklam.
                "
                />
            </Helmet>
            <motion.div
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={mainStyle}
            >
                <Header />
                <main>{children}</main>
            </motion.div>
            <Footer />
        </>
    );
};

export default Layout;
