import { Field, Form, Formik } from "formik";
import * as React from "react";
import { button } from "../../Button/style.css";
import {
    contactForm,
    errorStyle,
    infoPanel,
    inputStyle,
    labelStyle,
} from "./style.css";
import * as yup from "yup";
import useColor from "../../../context/ColorContext";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";

const formMachine = createMachine({
    id: "FormMachine",
    initial: "Default",
    states: {
        Default: {
            on: {
                SEND: {
                    target: "#FormMachine.Loading",
                },
            },
        },
        Loading: {
            on: {
                ERROR: {
                    target: "#FormMachine.Error",
                },
                SUCCESS: {
                    target: "#FormMachine.Success",
                },
            },
        },
        Error: {
            on: {
                RESET: {
                    target: "#FormMachine.Default",
                },
            },
        },
        Success: {
            on: {
                RESET: {
                    target: "#FormMachine.Default",
                },
            },
        },
    },
});

type StateType = "Default" | "Loading" | "Error" | "Success";

const ContactForm = () => {
    const { color } = useColor();
    const [state, send] = useMachine(formMachine);

    const handleSubmit = async (values: FormValues) => {
        try {
            send("SEND");
            const response = await fetch(
                process.env.CONTACT_FORM_ENDPOINT ?? "",
                { method: "POST", body: JSON.stringify(values) }
            );
            if (response.status === 200) {
                send("SUCCESS");
            } else {
                send("ERROR");
            }
        } catch (err) {
            send("ERROR");
        }
    };

    const resetForm = () => send("RESET");

    const visibility = ["Success", "Error"].includes(state.value as StateType)
        ? "hidden"
        : "visible";

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({
                errors,
                touched,
            }: {
                errors: Errors;
                touched: FormTouched;
            }) => {
                return (
                    <Form
                        className={contactForm({ color: "blue" })}
                        autoComplete="off"
                    >
                        <div
                            className={infoPanel({
                                state: state.value as StateType,
                            })}
                        >
                            {state.value === "Success"
                                ? "Wysłano wiadomość"
                                : "Błąd podczas wysyłania"}
                            <button
                                className={button({
                                    state: state.value as StateType,
                                })}
                                type="reset"
                                onClick={resetForm}
                            >
                                Wyślij nową wiadomość
                            </button>
                        </div>

                        <div style={{ visibility }}>
                            <label className={labelStyle} htmlFor="name">
                                Twoję imię
                            </label>
                            <Field
                                className={inputStyle({ color })}
                                type="text"
                                id="name"
                                name="name"
                            />
                            <p className={errorStyle}>
                                {(touched.name && errors.name) ?? ""}
                            </p>
                        </div>
                        <div style={{ visibility }}>
                            <label className={labelStyle} htmlFor="email">
                                Twój adres email
                            </label>
                            <Field
                                className={inputStyle({ color })}
                                type="text"
                                id="email"
                                name="email"
                            />
                            <p className={errorStyle}>
                                {(touched.email && errors.email) ?? ""}
                            </p>
                        </div>
                        <div style={{ visibility }}>
                            <label className={labelStyle} htmlFor="message">
                                Treść wiadomości
                            </label>
                            <Field
                                className={inputStyle({
                                    color,
                                    type: "textarea",
                                })}
                                id="message"
                                as={"textarea"}
                                name="message"
                            />
                            <p className={errorStyle}>
                                {(touched.message && errors.message) ?? ""}
                            </p>
                        </div>
                        <button
                            style={{ visibility }}
                            type="submit"
                            className={button({
                                color,
                                state: state.value as StateType,
                            })}
                            disabled={state.value === "Loading"}
                        >
                            Wyślij wiadomość
                        </button>
                    </Form>
                );
            }}
        </Formik>
    );
};

interface FormTouched {
    name?: boolean;
    email?: boolean;
    message?: boolean;
}
interface FormValues {
    name: string;
    email: string;
    message: string;
}

const initialValues: FormValues = {
    name: "",
    email: "",
    message: "",
};

const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required("Wprowadź swoję imię")
        .max(32, "Użyj maksymalnie 32 znaków"),
    email: yup
        .string()
        .required("Wprowadź swój adres email")
        .email("Wprowadź poprawny adres email")
        .max(48, "Użyj maksymalnie 48 znaków"),
    message: yup
        .string()
        .required("Wprowadź swoją wiadomość")
        .max(4000, "Użyj maksymalnie 4000 znaków"),
});

interface Errors {
    name?: string;
    email?: string;
    message?: string;
}

export default ContactForm;
