import { Link } from "gatsby";
import * as React from "react";
import useColor from "../../context/ColorContext";
import { button } from "../Button/style.css";
import Logo from "./Logo";
import { headerStyle } from "./style.css";

const Header = () => {
    const { color } = useColor();
    return (
        <header className={headerStyle}>
            <Link
                to="/"
                style={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Logo />
            </Link>
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Link
                    to="/contact"
                    style={{
                        display: "grid",
                        placeItems: "center",
                        width: "100%",
                    }}
                    tabIndex={-1}
                >
                    <button className={button({ color })}>
                        skontaktuj się
                    </button>
                </Link>
            </div>
        </header>
    );
};

export default Header;
