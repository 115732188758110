import * as React from "react";
import useColor from "../../../context/ColorContext";
import { contactPanelContainer, contactCard, iconStyle } from "./style.css";
import { FaEnvelope, FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa";

const ContactPanel = () => {
    const { color } = useColor();

    return (
        <div className={contactPanelContainer()}>
            Skontaktuj się <br />z nami
            <a href="tel:512006106" className={contactCard({ color })}>
                <FaPhoneAlt className={iconStyle} />
                +512 006 106
            </a>
            <a
                href="mailto: maciej@reklama-goldfish.pl"
                className={contactCard({ color })}
            >
                <FaEnvelope className={iconStyle} />
                maciej@reklama-goldfish.pl
            </a>
            <a
                href="https://www.google.com/maps/place/Wycz%C3%B3%C5%82kowskiego+14,+62-800+Kalisz/"
                target="_blank"
                rel="noreferrer"
                className={contactCard({ color })}
            >
                <FaMapMarkedAlt className={iconStyle} />
                Wyczółkowskiego 14-16, Kalisz
            </a>
        </div>
    );
};

export default ContactPanel;
